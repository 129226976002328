import React from 'react';

import Converter from './containers/Converter/Converter';

function App() {
  return (
    <Converter />
  );
}

export default App;
