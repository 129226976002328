import React, { Component } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';

import readXlsxFile from 'read-excel-file'

import dateFormat from 'dateformat';

import Spinner from '../../components/Spinner/Spinner';

class Converter extends Component {
  state = {
    loading: false,
    xmls: [],
    year: 2021,
    month: 1,
  }

  onFileChanged = (event) => {
    this.setState({loading: true});
    const file = event.target.files[0];
    readXlsxFile(file).then((rows) => {
      let index = 0;
      let xmls = [];
      rows.forEach((row) => {
        if (index < 2) {
          index++;
          return;
        }

        const jbkjs = row[1];
        const functionalClass = row[2];
        if (!jbkjs || !functionalClass) {
          return;
        }

        const currentDate = new Date();
        const dateString = dateFormat(currentDate, 'dd/mm/yyyy');

        let xml = '<OpstiPodaci>';
        xml += '<Korisnik>' + jbkjs + '</Korisnik>';
        xml += '<Mesec>' + this.state.month + '</Mesec>';
        xml += '<Godina>' + this.state.year + '</Godina>';
        xml += '<DatumSlanja>' + dateString + '</DatumSlanja>';
        xml += '</OpstiPodaci>';

        xml += '<ZaposleniNO>';
        xml += '<JBKJS>' + jbkjs + '</JBKJS>';
        xml += '<FunkcionalnaKlas>' + functionalClass + '</FunkcionalnaKlas>';
        xml += '<VrstaIdentifikacije>' + row[3] + '</VrstaIdentifikacije>';
        xml += '<Identifikacija>' + row[4] + '</Identifikacija>';
        xml += '<Ime>' + row[5] + '</Ime>';
        xml += '<Prezime>' + row[6] + '</Prezime>';
        xml += '<VrstaZaposlenja>' + row[7] + '</VrstaZaposlenja>';
        xml += '<TipSluzbenika>' + row[8] + '</TipSluzbenika>';
        xml += '<PlatnaGrupa>' + row[9] + '</PlatnaGrupa>';
        xml += '<PlatniRazred>' + row[10] + '</PlatniRazred>';
        xml += '<GodinaNapredovanja>' + row[11] + '</GodinaNapredovanja>';
        xml += '<GrupaNamestenika>' + row[12] + '</GrupaNamestenika>';
        xml += '<SifraRadnogMesta>' + row[13] + '</SifraRadnogMesta>';
        xml += '<ProcenatAngazovanja>' + row[14] + '</ProcenatAngazovanja>';
        xml += '<UkupanStaz>' + row[15] + '</UkupanStaz>';
        xml += '<StazURadnomOdnosu>' + row[16] + '</StazURadnomOdnosu>';
        xml += '<StazVanRadnogOdnosa>' + row[17] + '</StazVanRadnogOdnosa>';
        xml += '<StazJubilarna>' + row[18] + '</StazJubilarna>';
        xml += '<BeneficiraniStaz>' + row[19] + '</BeneficiraniStaz>';
        xml += '<MinuliRad>' + row[20] + '</MinuliRad>';
        xml += '<Koeficijent>' + row[21] + '</Koeficijent>';
        xml += '<DodKoeficijent>' + row[22] + '</DodKoeficijent>';
        xml += '<UkupniKoeficijent>' + row[23] + '</UkupniKoeficijent>';
        xml += '<OsnovicaNeto>' + row[24] + '</OsnovicaNeto>';
        xml += '<NetoOsnovnaPlata>' + row[25] + '</NetoOsnovnaPlata>';
        xml += '<OsnovicaBruto>' + row[26] + '</OsnovicaBruto>';
        xml += '<ProcenatUvecanja>' + row[27] + '</ProcenatUvecanja>';
        xml += '<DatumDejstvaPromene>' + row[28] + '</ProcenatUvecanja>';
        xml += '</ZaposleniNO>';

        const file = new Blob([ xml ], {type: 'xml'});
        const url = URL.createObjectURL(file);

        let name = 'dsRegZ_ZapNO1_';
        name += dateFormat(currentDate, 'yyyymmddHHMMss') + '_';
        name += jbkjs + '_';
        name += this.state.year + '_';
        name += this.state.month + '.xml';

        xmls.push({
          url: url,
          name: name,
        });
      });
      this.setState({loading: false, xmls: xmls});
    });
  }

  render() {
    let load = (
      <div style={{textAlign: 'center'}}>
        <TextField
            id="year"
            label="Godina"
            type="number"
            value={this.state.year}
            onChange={(event) => { this.setState({year: event.target.value}); }}
          />

      <br />
      <br />

      <TextField
            id="month"
            label="Mesec"
            type="number"
            value={this.state.month}
            onChange={(event) => { this.setState({month: event.target.value}); }}
          />

        <br />
        <br />

        <label htmlFor="file">
          <input id="file" name="file" type="file" onChange={this.onFileChanged} style={{display: 'none'}} />
          <Button color="secondary" variant="contained" component="span">Odaberi Excel fajl</Button>
        </label>
      </div>
    );

    if (this.state.loading) {
      load = <Spinner />;
    }

    let links = null;
    if (this.state.xmls.length) {
      const urls = this.state.xmls.map((el) => {
        return (
          <div key={el.name} style={{
            width: '100%',
            margin: '10px auto',
            textAlign: 'center',
          }}>
            <Link href={el.url} download={el.name} color="primary">{el.name}</Link>
          </div>
        );
      });

      links = (
        <div style={{
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          transition: '0.3s',
          width: '500px',
          margin: '10px auto',
          padding: '25px',
        }}>
          {urls}
        </div>
      );
    }

    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6">
              Excel to XML
            </Typography>
          </Toolbar>
        </AppBar>

        <br />

        { load }

        <br />
        <br />
        <br />

        { links }
      </div>
    );
  }
}

export default Converter;
